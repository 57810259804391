import { RouterView } from 'vue-router';
import AgendamentosIndex from '../agendamentos/Index.vue';
import FormulariosIndex from '../formularios/index.vue';
import GerenciarAsoIndex from '../gerenciar-aso/index.vue';
import DadosSolicitante from '../agendamentos/criar-agendamento/steps/DadosSolicitante.vue';
import DadosAgendamento from '../agendamentos/criar-agendamento/steps/DadosAgendamento.vue';
import Anexos from '../agendamentos/criar-agendamento/steps/Anexos.vue';
import Pcd from '../agendamentos/criar-agendamento/steps/Pcd.vue';
import Procedimentos from '../agendamentos/criar-agendamento/steps/Procedimentos.vue';
import DefinirClinica from '../agendamentos/criar-agendamento/steps/DefinirClinica.vue';
import Agendar from '../agendamentos/criar-agendamento/steps/Agendar.vue';
import Autoagendamento from '../agendamentos/criar-agendamento/steps/Autoagendamento';
import DadosFuncionario from '../agendamentos/criar-agendamento/steps/DadosFuncionario';
import DepositoAntecipado from '../deposito-antecipado/Index.vue';
import Extratos from '../deposito-antecipado/extratos/Index.vue';
import ExibirExtrato from '../deposito-antecipado/extratos/Exibir.vue';

const gestaoMedicaV3 = {
    path: '/gestao-medica-v3',
    name: 'Gestão Médica',
    redirect: '/gestao-medica-v3/agendamentos',
    component: RouterView,
    meta: {
        requiresAuth: true,
        permission: 'gestao_medica_v3:view'
    },
    children: [
        {
            path: 'agendamentos',
            name: 'agendamentos-v3-index',
            component: AgendamentosIndex,
            children: [
                {
                    path: 'dados-solicitante',
                    name: 'agendamentos-v3-dados-solicitante',
                    component: DadosSolicitante
                },
                {
                    path: 'dados-agendamento',
                    name: 'agendamentos-v3-dados-agendamento',
                    component: DadosAgendamento
                },
                {
                    path: 'anexos',
                    name: 'agendamentos-v3-anexos',
                    component: Anexos
                },
                {
                    path: 'pcd',
                    name: 'agendamentos-v3-pcd',
                    component: Pcd
                },
                {
                    path: 'procedimentos',
                    name: 'agendamentos-v3-procedimentos',
                    component: Procedimentos
                },
                {
                    path: 'definir-clinica',
                    name: 'agendamentos-v3-definir-clinica',
                    component: DefinirClinica
                },
                {
                    path: 'agendar',
                    name: 'agendamentos-v3-agendar',
                    component: Agendar
                },
                {
                    path: 'autoagendamento',
                    name: 'agendamentos-v3-auto-agendamento',
                    component: Autoagendamento
                },
                {
                    path: 'dados-funcionario',
                    name: 'agendamentos-v3-dados-funcionario',
                    component: DadosFuncionario
                }
            ]
        },
        {
            path: 'deposito-antecipado',
            name: 'Depósito Antecipado',
            meta: { permission: 'gestamedica_painel_deposito_antecipado:view' },
            component: RouterView,
            children: [
                {
                    path: '',
                    name: 'Listagem de Depósitos',
                    component: DepositoAntecipado
                },
                {
                    path: 'extratos',
                    name: 'Extratos',
                    component: Extratos
                },
                {
                    path: 'extratos/:id',
                    name: 'Exibir Extrato',
                    component: ExibirExtrato
                }
            ]
        },
        {
            path: 'formularios',
            name: 'Formulários',
            component: FormulariosIndex
        },
        {
            path: 'gerenciar-aso',
            name: 'Atestados de Saúde Ocupacional',
            component: GerenciarAsoIndex
        }
    ]
};

export default gestaoMedicaV3;
